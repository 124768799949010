<template>
  <div class="hello">
    <!-- <HelloWorld/> -->
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
  <!-- <a class="navbar-brand">My Plan</a>
  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button> -->

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" href="/home">Home</a>
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" href="/daplan">Da's Plan</a>
      </li>  -->
       <!-- <li class="nav-item">
        <a class="nav-link" href="/list">Diary</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/special">Special</a>
      </li> -->
    </ul>
    <!-- <form class="form-inline my-2 my-lg-0">
      <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
      <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
    </form> -->
  </div>
</nav>
  </div>
</template>

<script>
// import HelloWorld from './HelloWorld.vue'

export default {
  name: "HelloWorld",
  // components:{
  //   HelloWorld
  //   },
  data() {
    return {
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      setTimeout(function () {
        location.reload();
      }, 500);
      this.$router.push("/login");
    },
  },
  mounted() {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

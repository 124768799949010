import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VCalendar from 'v-calendar';
// import store from './store';

Vue.config.productionTip = false

Vue.use(VCalendar);

new Vue({
  // store,
  router,
  VCalendar,
  render: h => h(App),
}).$mount('#app')

import Vue from "vue";
import Router from "vue-router";
import Home from './components/Home.vue';

Vue.use(Router);
const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      alias: "/home",
      name: "home",
      component: Home
    },
  ]
});

// router.beforeEach((to, from, next) => {
//   var page = to.path.split("/")
//   var qrcode = ''
//   if (page[1] == 'plans') {
//     qrcode = to.path
//   }
//   const publicPages = ['/', '/login', qrcode,'/research'];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('planoum');
//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   if (authRequired && !loggedIn) {
//     next('/');
//     // next();
//   } else {
//     next();
//   }
// });

export default router;

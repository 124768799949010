<template>
  <div class="hello">
    <div class="container mt-5">
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">น้ำหนักปุ๋ย/ข้าว</label>
    <input type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" v-model="fertilizer_weight">
  </div>
  <div class="mb-3">
    <label for="exampleInputPassword1" class="form-label">จำนวนไร่</label>
    <input type="number" class="form-control" id="exampleInputPassword1" v-model="rai">
  </div>
  <div class="mb-3">
    <label for="exampleInputPassword2" class="form-label">จำนวนลูก</label>
    <input type="number" class="form-control" id="exampleInputPassword2" v-model="children">
  </div>
  {{(((fertilizer_weight*children)/rai)*6.25).toFixed(2)}}
<hr>
  <div class="mb-3 mt-5">
    <label for="exampleInputPassword2" class="form-label">จำนวนเที่ยว</label>
    <input type="number" class="form-control" id="exampleInputPassword2" v-model="childrentwo">
  </div>
  {{(100/childrentwo).toFixed(2)}}
  <hr>
  <div class="mb-3 mt-5">
    <label for="exampleInputPassword2" class="form-label">จำนวนไร่</label>
    <input type="number" class="form-control" id="exampleInputPassword2" v-model="raitwo">
  </div>
  {{(raitwo*6.25).toFixed(2)}}
    </div>
    

  </div>

  
</template>

<script>
export default {
  data() {
    return {
      fertilizer_weight:40,
      rai:0,
      children:0,
      childrentwo:0,
      raitwo:0
    };
  },
  methods: {
  },

  mounted() {  
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
